;(function () {
  Drupal.behaviors.ga_event_breadcrumb = {
    attach: function (context, settings) {
      function breadcrumbClick(event) {
        var breadcrumbText;
        var breadcrumbItemText = event.target.innerText.split("›").pop().trim();

        var currEl = event.target;
        while (currEl.getAttribute("itemprop") === null && currEl.parentNode !== null) {
          var attr = currEl.getAttribute("itemprop");
          if (attr !== null) { break; }
          currEl = currEl.parentNode;
        }
        if (currEl !== null) {
          breadcrumbText = currEl.innerText.trim();

          var category = "HIDG - Breadcrumb";
          dataLayer.push({
            "event":    "trackEventInteractive",
            "category": category,
            "action":   breadcrumbText,
            "label":    breadcrumbItemText
          });
        }

      }

      var breadcrumb = document.querySelectorAll("[itemprop=breadcrumb] li")[0];
      if (typeof breadcrumb !== "undefined") {
        breadcrumb.addEventListener("click", breadcrumbClick);
      }
    }
  };
})();