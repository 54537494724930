(function ($) {

  Drupal.behaviors.hidDocumentWidgets = {
    attach: function (context, settings) {

        if (settings.doctypechanged == 'true') {
            //$('#edit-doc-type').attr('disabled', 'disabled');
         //$('#edit-doc-type')[0].selectedIndex = 0;
        // $('#category-wrapper').find('select')[0].selectedIndex = 0;
          //$('#brand-wrapper').find('select')[0].selectedIndex = 0;
         //  $('#product-wrapper').find('select')[0].selectedIndex = 0;
         
        }
        if (settings.categorychanged == 'true') {
          // $('#category-wrapper').find('select').attr('disabled', 'disabled');
           // $('#category-wrapper').find('select')[0].selectedIndex = 0;
            // $('#brand-wrapper').find('select')[0].selectedIndex = 0;
          // $('#product-wrapper').find('select')[0].selectedIndex = 0;
        }
        if (settings.brandchanged == 'true') {
          //  $('#brand-wrapper').find('select').attr('disabled', 'disabled');
         // $('#brand-wrapper').find('select')[0].selectedIndex = 0;
        //$('#product-wrapper').find('select')[0].selectedIndex = 0;
        }

         //$('#hid-document-widgets-search-form').find('select').selectmenu();
       //  $('.form-item-doc-type').find('select').selectmenu({"width": "178px"});
        // $('.form-item-brand').find('select').selectmenu({"width": "178px"});
        // $('.form-item-product').find('select').selectmenu({"width": "178px"});
        $('a.ui-selectmenu').bind("click", function(e){e.preventDefault(); return false;});

      //$('#edit-doc-type', context).change(function () {
      //  $(this).attr('disabled', 'disabled');
      //});
    }
  };

})(jQuery);

