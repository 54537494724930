;(function ($) {
  Drupal.behaviors.footer_contact_us_behavior = {
    attach: function (context, settings) {

      /**
       * Event Listener to close the footer on click.
       *
       * @param e
       *   Event.
       */
      function closeFooterOnClick(e) {
        var closeButton = document.querySelector('.contact-button__close');
        if (parseInt(closeButton.style.height) > 0) {
          closeButton.click();
        }
        // End of if the footer is open.
      }
      // End of function closeFooterOnClick().

      // Add footer overlay div to body.
      var footerOverlay = document.createElement('div');
      footerOverlay.classList.add('footer-overlay');
      footerOverlay.addEventListener('click', function(e) {
        closeFooterOnClick();
      });
      document.body.appendChild(footerOverlay);

      var links = document
        .querySelectorAll('#region-sf-contact-us a[data-reveal-id]');

      for (var i = 0; i < links.length; i++){
        links[i].addEventListener('click', closeFooterOnClick);
      }
    }
    // End of behavior.attach.
  }
  // End of behavior object.
})(jQuery);
