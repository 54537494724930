(function ($) {
  Drupal.behaviors.contactUs = {
    attach: function (context, settings) {
      changeContactUsForm();

      function changeContactUsForm() {
        var els = document.querySelectorAll('.region-top-bar [data-reveal-id=riFirstModal], .region-sf-contact-us [data-reveal-id=riFirstModal]');
        function contactClicked(el) {
          console.log(el, 'clicked');
          el.preventDefault();
          document.querySelector('#request-info-modal-link').click();
        };
        Array.from(els).forEach(function(el) {
          el.addEventListener('click', contactClicked);
        });
      }
    }
  };
})(jQuery);
