/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
(function ($, Drupal, window, document, undefined) {

  function hideGroup() {
    if ((window.location.pathname.split("/").length - 1) > 2) {
      $('.group-description').hide();
      $('.group').hide();

    }
  }

  function hideView() {
    if ((window.location.pathname.split("/").length - 1) == 2) {

      /* 			$('.view-id-product_display > .view-content').css('display', 'none'); */
      $('.view-brand-display-alpha').css('display', 'block');

      $('.view-id-product_display > .view-content').hide();
    }
  }
  $(document).ready(function () {

    hideGroup();

    hideView();

    $("#block-views-exp-product-display-page .form-type-bef-checkbox label").bind("click", function () {
      /* 		$('.view-id-product_display > .view-content').css('display', 'block'); */
      /* 		$('.view-brand-display-alpha > .view-content').css('display', 'none');		 */
      $('.view-brand-display-alpha').fadeOut('slow');
      $('.view-id-product_display > .view-content').delay(2500).fadeIn(1000);
    });

    $('#name-and-slogan').after('<div id="login-icon"></div><div id="lang-icon"></div>');
    //$('#views-exposed-form-product-display-page .selected, #views-exposed-form-product-display-page input[checked="checked"]').siblings().find('.deselect').css("visibility", "visible");
    //$(".form-type-bef-checkbox .option").parent().children("div.deselect").css("visibility","visible");
    //$("#navigation").slideToggle();

// Scripts for new mobile menu icons to control the 2 different divs and integrate with TB MM Module  //

    /* 3 way toggle on nav - Global Login icon */
    $("#login-icon").bind("click", function () {
      if ($('#lang-icon').hasClass('active')) {
        $('#lang-icon').removeClass('active');
        $('#block-locale-language').fadeToggle();
      }
      if ($('.nav-collapse').height('in')) {
        $('.nav-collapse').fadeOut();
      }
      if ($('#block-views-exp-search-page').hasClass('active')) {
        $('#block-views-exp-search-page').removeClass('active');
      }
      if ($('.tb-megamenu-button').hasClass('active')) {
        $('.tb-megamenu-button').removeClass('active');
      }
      $('#block-hid-login-block-hid-login-block').fadeToggle(300);
      $(this).toggleClass("active");
    });

    /* Language icon */
    $("#lang-icon").bind("click", function () {
      if ($('#login-icon').hasClass('active')) {
        $('#login-icon').removeClass('active');
        $('#block-hid-login-block-hid-login-block').fadeToggle();
      }
      if ($('.nav-collapse').hasClass('in')) {
        $('.nav-collapse').fadeOut();
      }
      if ($('.tb-megamenu-button').hasClass('active')) {
        $('.tb-megamenu-button').removeClass('active');
      }
      if ($('#block-views-exp-search-page').hasClass('active')) {
        $('#block-views-exp-search-page').removeClass('active');
      }
      $('#block-locale-language').fadeToggle(300);
      $(this).toggleClass('active');
    });

    /* Work with TB mega menu icon */
    $('.tb-megamenu-button').click(function () {
      if ($('#login-icon').hasClass('active')) {
        $('#login-icon').removeClass('active');
        $('#block-hid-login-block-hid-login-block').slideToggle();
      }
      if ($('.nav-collapse').hasClass('in')) {
        $('.nav-collapse').fadeOut();
      } else {
        $('.nav-collapse').fadeIn();
      }
      if ($('#lang-icon').hasClass('active')) {
        $('#lang-icon').removeClass('active');
        $('#block-locale-language').fadeToggle();
      }
      if ($('#block-views-exp-search-page').hasClass('active')) {
        $('#block-views-exp-search-page').removeClass('active');
      } else {
        $('#block-views-exp-search-page').addClass('active');
      }
      $(this).toggleClass('active');
    });

    var searchIcon = $("<i></i>");
    searchIcon.addClass("search");
    $("#block-views-exp-search-page").prepend(searchIcon);

// Language Selector this renders markup for selector //

    var width = $(window).width();

    /*
     $(".language-menu").remove();
     var langContent = $("#block-locale-language h2.block-title").html()
     var langList = $(".language-switcher-locale-url").html()
     $("#block-locale-language h2.block-title").hide();
     var outerList = $("<ul></ul>");
     outerList.addClass("language-menu");
     var firstLink = $("<li>"+langContent+"</li>");
     var innerList = $("<ul></ul>");
     innerList.append(langList);
     firstLink.append(innerList);
     outerList.append(firstLink);
     $("#block-locale-language .content").append(outerList);
     $(".language-switcher-locale-url").hide();
     */
    var langContent = $("#block-locale-language h2.block-title").html();
    var langSel = $("<select></select>");
    langSel.addClass("lang-selector");


    var group = $("<option></option>");
    group.html(langContent);
    group.attr("selected", "selected");
    group.addClass("lang-opt-none");
    group.attr("value", "");
    langSel.append(group);

    $(".language-link").each(function () {

      var html = $(this).html();
      var path = $(this).attr("href");

      var opt = $("<option></option>");
      opt.html(html);
      opt.attr("value", path);

      langSel.append(opt);

    });

    langSel.change(function () {

      var path = $(this).val();

      if (path != '')
        window.location.href = path;

    });

    $('select#edit-language').children().each(function () {
      if ($(this).val() == 'en')
        $(this).addClass("en-flag");
      else if ($(this).val() == 'zh-hans')
        $(this).addClass("zh-flag");
      else if ($(this).val() == 'de')
        $(this).addClass("de-flag");
      else if ($(this).val() == 'fr')
        $(this).addClass("fr-flag");
      else if ($(this).val() == 'es')
        $(this).addClass("es-flag");
      else if ($(this).val() == 'ja')
        $(this).addClass("ja-flag");
      else if ($(this).val() == 'ko')
        $(this).addClass("ko-flag");
      else if ($(this).val() == 'pt-br')
        $(this).addClass("pt-br-flag");
      else if ($(this).val() == 'ru')
        $(this).addClass("ru-flag");
      else if ($(this).val() == 'th')
        $(this).addClass("th-flag");
      else if ($(this).val() == 'it')
        $(this).addClass("it-flag");

    });

  });
})(jQuery, Drupal, this, this.document);
