jQuery(document).ready(function () {

  var timeout;
  var checked = false;

  jQuery("#views-exposed-form-product-display-page .form-item.form-type-bef-checkbox").bind("click", function () {
    checked = false;
    jQuery("#views-exposed-form-product-display-page .form-item.form-type-bef-checkbox input").each(function () {
      if (jQuery(this)[0].checked == true) {
        checked = true;

      }
    })

    window.clearTimeout(timeout); //clears the timeout to prevent to many requests being submitted.
    timeout = window.setTimeout(function () {
      if (checked == true) {
        jQuery('#edit-submit-product-display').click();
      } else {
        jQuery('#edit-reset').click();
      }
    }, 100);

//jQuery('#edit-submit-product-display').click();
    //console.log('works');
  });

  //jQuery('#block-views-exp-product-display-page input[checked="checked"] ~ label').parent().addClass("selected");
  //		jQuery('#block-views-exp-product-display-page input[checked="checked"] + div').addClass("selected");
  // navigation ul selector
  //jQuery('#megamenu-main-menu').mobileSelect();
  //jQuery('ul.tabs').mobileSelect();
  // Worldwide Sales Tool Breadcrumbs
  //var str = "";
  //jQuery(".views-exposed-widgets select option:selected").each(function () {
  //    str += jQuery(this).text() + " > ";
  //  });
  //jQuery("div.breadtrails").text(str);


  /*var langContent = jQuery("#block-locale-language h2.block-title").html()
   jQuery("#block-locale-language h2.block-title").hide();

   var langSel = jQuery("<select></select>");
   langSel.addClass("lang-selector");

   var group = jQuery("<option></option>");
   group.html(langContent);
   //group.attr("selected", "selected");
   group.addClass("lang-opt-none");
   group.attr("value", "");
   langSel.append(group);


   jQuery(".language-link").each(function(){

   var html = jQuery(this).html();
   var path = jQuery(this).attr("href");

   var opt = jQuery("<option></option>");
   opt.html(html);
   opt.attr("value", path);

   langSel.append(opt);

   });



   jQuery("#block-locale-language .content").append(langSel);

   langSel.change(function(){

   var path = jQuery(this).val();

   if(path != '') window.location.href = path;

   });

   jQuery(".lang-selector").selectmenu({"width": "140px"});*/

  var fw = "178px";

  if (jQuery(window).width() < 980 && jQuery(window).width() > 768) {
    var fw = "135px";
  }

  /**
   jQuery('select#edit-language').children().each(function(){
   if(jQuery(this).val() == 'en')
   jQuery(this).addClass("en-flag");
   else if (jQuery(this).val() == 'zh-hans')
   jQuery(this).addClass("zh-flag");
   else if (jQuery(this).val() == 'de')
   jQuery(this).addClass("de-flag");
   else if (jQuery(this).val() == 'fr')
   jQuery(this).addClass("fr-flag");
   else if (jQuery(this).val() == 'es')
   jQuery(this).addClass("es-flag");
   else if (jQuery(this).val() == 'ja')
   jQuery(this).addClass("ja-flag");
   else if (jQuery(this).val() == 'ko')
   jQuery(this).addClass("ko-flag");
   else if (jQuery(this).val() == 'pt-br')
   jQuery(this).addClass("pt-br-flag");
   else if (jQuery(this).val() == 'ru')
   jQuery(this).addClass("ru-flag");
   
   });


   jQuery('select#edit-language').selectmenu({
   icons: [
   {find: '.en-flag', icon: 'en-flag'},
   {find: '.zh-flag', icon: 'zh-flag'},
   {find: '.de-flag', icon: 'de-flag'},
   {find: '.fr-flag', icon: 'fr-flag'},
   {find: '.es-flag', icon: 'es-flag'},
   {find: '.ja-flag', icon: 'ja-flag'},
   {find: '.ko-flag', icon: 'ko-flag'},
   {find: '.pt-br-flag', icon: 'pt-br-flag'},
   {find: '.ru-flag', icon: 'ru-flag'},
   ]
   });



   jQuery('a.ui-selectmenu').bind("click", function(e){e.preventDefault(); return false;});*/


  jQuery(document).ajaxStart(function () {
    jQuery(this).find('.form-submit').attr("disabled", "disabled");
  });
  jQuery(document).ajaxSuccess(function () {
    jQuery(this).find('.form-submit').removeAttr("disabled");
  });

  //display genuine hid additional selections if main category has a selection
  if (jQuery("#edit-field-partner-prod-category-tid-i18n").val() != 'All') {
    jQuery('#edit-field-partner-prod-category-tid-i18n-1-wrapper').css("display", "block");
    jQuery('#edit-field-partner-nid-selective-wrapper').css("display", "block");
    jQuery('#edit-field-brand-tid-selective-wrapper').css("display", "block");
  }

  jQuery('#views-exposed-form-genuine-hid-technology-page').find('#edit-field-partner-prod-category-tid-i18n').change(function () {
    jQuery('#edit-field-partner-prod-category-tid-i18n-1').val(jQuery('options:first', '#edit-field-partner-prod-category-tid-i18n-1').val());
    jQuery('#edit-field-partner-nid-selective').val(jQuery('options:first', '#edit-field-partner-nid-selective').val());
    jQuery('#edit-field-brand-tid-selective').val(jQuery('options:first', '#edit-field-brand-tid-selective').val());
  });

  /*
   * For hover on TB Mega Menu product and solutiuon toggle
   * 
   * @authors Caryn & Melissa
   */

  jQuery('.solution-drop')
  // On mouse over, move the background on hover

    .mouseover(function () {
      jQuery('.solution-drop .tb-megamenu-row').css('display', 'block').css('z-index', '999').fadeIn(1000);
    })

    // On mouse out, move the background back
    .mouseout(function () {
      jQuery('.solution-drop .tb-megamenu-row').css('display', 'none').css('z-index', '-999').fadeOut(1000);
    });

  jQuery('.product-drop')
  // On mouse over, move the background on hover

    .mouseover(function () {
      jQuery('.product-drop .tb-megamenu-row').css('display', 'block').css('z-index', '999').fadeIn(1000);
    })

    // On mouse out, move the background back
    .mouseout(function () {
      jQuery('.product-drop .tb-megamenu-row').css('display', 'none').css('z-index', '-999').fadeOut(1000);
    });

});

jQuery(window).load(function () {
  new ResizeSensor(jQuery('#region-content'), adjustHeight);
  new ResizeSensor(jQuery('#region-aside .block'), adjustHeight);
  adjustHeight();
  jQuery(".hid-genuine-block").show();
});

function adjustHeight() {
  var contentHeight_children = 0;
  jQuery(".region-content").children().each(function () {
    // CO - 11/22/17 - only count visible elements
    // otherwise, height could overreach
    if (jQuery(this).is(":visible") == true) {
      contentHeight_children = contentHeight_children + jQuery(this).outerHeight(true);
    }
  });
  var sideHeight_children = 0;
  jQuery(".region-aside").children().each(function () {
    if (jQuery(this).is(":visible") == true) {
      // CO - 11/22/17 - only count visible elements
      // otherwise, height could overreach
      sideHeight_children = sideHeight_children + jQuery(this).outerHeight(true);
    }
  });
  if (sideHeight_children == 0) {
    return;
  }
  if (jQuery(window).width() <= 786) {
    jQuery("#content-left").height("auto");
    jQuery("#sidebar").height("auto");
    jQuery('.hid-genuine-block').css({"position": "relative", "margin-left": "auto", "margin-right": "auto"});
  } else if (contentHeight_children > sideHeight_children) {
    jQuery("#content-left").height("auto");
    jQuery("#sidebar").height(contentHeight_children);
    jQuery('.hid-genuine-block').css({"position": "absolute", "margin-left": "0", "margin-right": "0"});
  } else {

    jQuery("#content-left").height(sideHeight_children);
    jQuery("#sidebar").height("auto");
    jQuery('.hid-genuine-block').css({"position": "relative", "margin-left": "0", "margin-right": "0"});
  }
}
