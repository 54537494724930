;(function ($) {
  Drupal.behaviors.cn_view_tracker = {
    attach: function (context, settings) {
      if (window.location.hostname === 'www.hidglobal.cn') {
        var el = document.createElement('script');
        el.setAttribute('id', 'ebsgovicon');
        el.setAttribute('src', 'https://szcert.ebs.org.cn/govicon.js?id=a06abbf7-f556-4f2a-9c0e-36fb4fdc7f8a&width=75&height=105&type=1');
        el.setAttribute('charset', 'utf-8');
        document.querySelector('body').appendChild(el);
      }
    }
  }
})(jQuery);