(function ($) {
  Drupal.behaviors.flippyBoxes = {
    attach: function (context, settings) {
      // Implements flippy box behavior on the services blocks.
      var flipBoxBlock = $('#block-block-114, #block-block-115');
      if (flipBoxBlock.length > 0) {
        $('body').addClass('service-banner');

        $('.hover').hover(function () {
          $(this).addClass('flip');
        }, function () {
          $(this).removeClass('flip');
        });

        flipBoxBlock.detach();
        flipBoxBlock.insertAfter('.title-small');
      }
    }
  };
})(jQuery);