;(function ($) {
  Drupal.behaviors.fix_invalid_svg_fonts = {
    // Changes bad font descriptors passed by Illustrator into compliant styles.
    attach: function () {
      var oldFontTag = document.querySelectorAll("[font-family]");
      for (var i = oldFontTag.length; i < oldFontTag.length; i++) {
        var el = oldFontTag[i];
        var currentFont = el.getAttribute("font-family");
        if (currentFont === "Gotham-Bold") {
          el.setAttribute("font-family", "Montserrat");
          el.style.fontWeight = "bold";
        }
        else if (currentFont === "Gotham") {
          el.setAttribute("font-family", "Montserrat");
        }
        else if (currentFont === "Gotham-Book") {
          el.setAttribute("font-family", "Montserrat");
          el.style.fontWeight = "normal";
        }
        else if (currentFont === "Gotham-BookItalic") {
          el.setAttribute("font-family", "Montserrat");
          el.style.fontWeight = "normal";
          el.style.fontStyle = "italic";
        }
        else if (currentFont === "Gotham-Medium") {
          el.setAttribute("font-family", "Montserrat");
          el.style.fontWeight = "500";
        }
      }
    }
  };
})(jQuery);