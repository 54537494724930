;(function ($) {
  Drupal.behaviors.fix_accordion_behavior = {
    attach: function (context, settings) {

      /**
       * Implements custom Cubic easeInOut scroll behavior.
       *
       * @param targetY
       *   Target Y location for the scroll.
       * @param duration
       *   Duration of the scroll.
       */
      function customScroll(targetY, duration) {
        var timeStart = undefined;
        var startY = window.pageYOffset;
        duration = duration < 1000 ? duration * 1000 : duration;

        // Cubic easeInOut
        function timing(t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }

        /**
         * Runs on requestAnimationFrame, updating Y scroll.
         *
         * @param timeCurrent
         *   Current timestamp (by animation frame API).
         */
        function loop(timeCurrent) {
          if (!timeStart) {
            timeStart = timeCurrent;
          }

          var percentTime = (timeCurrent - timeStart) / duration;
          var percentY    = timing(percentTime);

          var currentY = startY + (targetY - startY) * percentY;

          if (timeCurrent < (timeStart + duration)) {
            window.scrollTo(window.pageXOffset, currentY);
            requestAnimationFrame(loop);
          }
          else {
            window.scrollTo(window.pageXOffset, targetY)
          }
        }
        
        requestAnimationFrame(loop);
      }
      // End of function customScroll().

      /**
       * On click event handler that triggers "scroll to accordion" behavior.
       *
       * @param event
       */
      function scrollToAccordion(event) {
        var fromTop = 0;
        var el = event.currentTarget || event.target;
        if ('ownerSVGElement' in el) {
          el = el.ownerSVGElement;
        }

        while (el !== document.body
          && typeof(el) !== 'undefined'
          && el !== null
        ) {
          fromTop += el.offsetTop;
          el = el.offsetParent;
        }

        // Offset value depends on scroll direction.
        var offsetHeader = window.pageYOffset < fromTop ? 80 : 120;

        customScroll(fromTop - offsetHeader, 1);
      }

      // Assign event handlers to proper DOM elements.
      var accordions = document.querySelectorAll('.field-collection-item-field-gateway-need-accordion');
      for (var i = 0; i < accordions.length; i++) {
        accordions[i].addEventListener('click', function(event) {
          // SetTimeOut used to allow foundation accordion behavior to complete first.
          setTimeout(function() {scrollToAccordion(event)}, 500);
        });
      }
    }
  }
})(jQuery);
