;(function ($) {
  Drupal.behaviors.solution_boxes_restyle = {
    attach: function () {
      var boxes_candidates = document.querySelectorAll("[class*=small-block-grid]");
      for (var i = 0; i < boxes_candidates.length; i++) {
        if (boxes_candidates[i].querySelectorAll(".sol-pg-box").length > 0) {
          boxes_candidates[i].className += " flex-flippy-wrapper";
        }
      }
    }
  };
})(jQuery);
