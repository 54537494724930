/**
 * Created by peter on 6/24/16.
 */

(function ($) {
  Drupal.behaviors.drivers_link_fix = {
    attach: function (context, settings) {
      $('.view-drivers a:contains("Download")').each(
        function(i,e,a) {
          var href = $(this).attr('href');
          var rel = $(this).attr('rel');
          $(e).parent().parent().parent().parent().find('a[rel*=lightframe]').attr({
            href: href,
            rel: rel
          });
        }
      );
    }
  };

  Drupal.behaviors.lumidigm_dtk_5_fix = {
    attach: function (context, settings) {
      var dtk = document.querySelectorAll('td a[href*=lumidigm-sdk-5]');
      if (dtk.length > 0) {
        var current_text = dtk[0].text;
        if (current_text == 'Lumidigm DTK') {
          dtk.text = 'Lumidigm DTK 5';
        };
      }
    }
  };

  /**
   * Band-aid fix for messed-up breadcrumbs on some product pages.
   *
   * From: https://trello.com/c/oae4XdTn/
   */
  Drupal.behaviors.fix_breadcrumbs = {
    attach: function (context, settings) {
      var links = document.querySelectorAll('.breadcrumb a');
      // For each link inside a breadcrumb, fix it and re-write it.
      for (var i = 0; i < links.length; i++) {
        var newHref = links[i].getAttribute('href')
          .replace(/-$/, '')
          .replace('-/', '/');
        links[i].setAttribute('href', newHref);
      }
      // End for each link inside a breadcrumb.
    }
    // End attach property of drupal behavior.
  };
  // End band-aid fix for breadcrumbs.
})(jQuery);
