(function ($) {
  Drupal.behaviors.requestInfo = {
    attach: function (context, settings) {
      setupForm();

      /**
       * Setups the request info contact form.
       */
      function setupForm() {
        var requestInfo = document.querySelector("[id^='request-info-modal-link']");
        var mktoForm = document.querySelector('.mktoForm');

        if (requestInfo && mktoForm) {
          var defLinkLen = "request-info-modal-link".length;
          var lang = requestInfo.getAttribute('id').substring(defLinkLen);
          var formId;
          switch (lang) {
            case "-fr":
              formId = '1930';
              break;
            case "-de":
              formId = '1924';
              break;
            case "-es":
              formId = '1928';
              break;
            case "-pt":
              formId = '1936';
              break;
            case "-jp":
              formId = '1932';
              break;
            case "-kr":
              formId = '1934';
              break;
            case "-ru":
              formId = '1946';
              break;
            case "-zh":
              formId = '715';
              break;
            default:
              formId = '1892';
          }

          mktoForm.id = 'mktoForm_' + formId;

          // Ensure the handler gets added once.
          if (!requestInfo.formId) {
            requestInfo.formId = formId;
            requestInfo.addEventListener('click', contactFormHandler, true);
          }
        }
      }

      /**
       * Click event handler for the request info contact form.
       *
       * @param {document#event} event
       *
       * @listens document#click
       */
      function contactFormHandler(event) {
        $.getScript('//info.hidglobal.com/js/forms2/js/forms2.min.js')
          .done(function onSuccess() {
            // Rerun the Teleporter so that stored params are added.
            if (typeof Drupal.behaviors.marketoCookieTeleporter !== 'undefined') {
              Drupal.behaviors.marketoCookieTeleporter.attach(context, settings);
            }
            // Load the contact us form.
            loadMktoForm(event.target.formId);
          })
          .fail(function onFailure(jqxhr, settings, exception) {
            // Display an error message.
            var header = Drupal.t('Sorry, there was an error.');
            var message = Drupal.t('Please try again or visit our <a href="@url">contact page</a> for a list of other contact methods.',
              {'@url': '/customer-service/contact-customer-service'});
            setMessage(header, message);
            // Log error to Sentry.
            var error = new Error('Failed to load Forms2: ' + exception);
            logError(error);
          });

        event.target.removeEventListener(event.type, arguments.callee, true);
      }

      /**
       * Loads a Marketo contact form.
       *
       * @param {string} formId
       */
      function loadMktoForm(formId) {
        MktoForms2.loadForm('//info.hidglobal.com', '289-TSC-352', formId, function formInitialized(form) {
          var formElem = form.getFormElem()[0];

          // Display the form.
          formElem.classList.add('mktoReady');
          // Set hidden form values not handled by Teleporter.
          var conversion_page_url__c = window.location.host + window.location.pathname;
          var tempURL = new URLSearchParams(window.location.search);
          if (tempURL.has('conversionMarkURL')) {
            conversion_page_url__c = tempURL.get('conversionMarkURL');
          }
          form.addHiddenFields({
            'Conversion_Page_URL__c': conversion_page_url__c,
            'formpositiononthewebsite': 'request-info'
          });
          // Add custom submit callback.
          form.onSuccess(function (values, followUpUrl) {
            var header = Drupal.t('Request Sent');
            var message = Drupal.t('Thank you for your interest in HID Global. We will follow up shortly with additional information.');
            setMessage(header, message);
            return false;
          });
        });
      }

      /**
       * Logs an error to Sentry.
       *
       * @param {object} error
       */
      function logError(error) {
        if (window.Raven) {
          Raven.captureException(error,
            {
              level: 'fatal',
              tags: {component: 'Contact Us'}
            });
        }
      }

      /**
       * Sets a message inside the form's parent element.
       *
       * @param {string} header
       * @param {string} message
       */
      function setMessage(header, message) {
        var form = document.querySelector('.mktoForm');
        var formParent = form.parentNode;
        var headerElem = document.createElement('h4');
        var messageElem = document.createElement('p');

        headerElem.textContent = header;
        messageElem.innerHTML = message;
        formParent.removeChild(form);
        formParent.appendChild(headerElem);
        formParent.appendChild(messageElem);
      }
    }
  };
})(jQuery);
