;(function () {
  if ( typeof NodeList.prototype.forEach === "function" ) return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();

(function () {
  Drupal.behaviors.event_tracking_gateway_accordions = {
    attach: function (context, settings) {

      // Get some variables.
      var title;
      try {
        title = document.querySelector('#page-title')
          .innerHTML
          .replace(/&[a-z]+;/, ' ')
          .replace(/[^0-9a-zA-Z -_]+/, ' ');
      }
      catch (e) {
        title = 'no title';
      }
      var gatewayAccordions = document.querySelectorAll('.accordion-section-teaser');
      var gatewaySolutions = document.querySelectorAll('.field-collection-item-field-solution-page-teaser');

      // Attach on click event to accordions.
      gatewayAccordions.forEach(function(el, i, a) {
        el.addEventListener('click', gtmAccordionTeaserClick);
      });

      // Attach on click event to solutions.
      gatewaySolutions.forEach(function(el, i, a) {
        el.addEventListener('click', gtmSolutionTeaserClick);
      });

      /**
       * Record click on gateway teaser accordion.
       */
      function gtmAccordionTeaserClick(e) {

        var accordion;
        try {
          accordion = e.target
            .closest('.accordion-section-teaser')
            .querySelector('h2')
            .innerHTML
            .replace(/&[a-z]+;/, '')
            .replace(/[^0-9a-zA-Z -_]+/, ' ');
        }
        catch (e) {
            accordion = 'accordion not found';
        }

        console.log(title + ' -- ' + accordion);

        dataLayer.push({
          'event':    'trackEventInteractive',
          'category': 'Gateway Accordion',
          'action':   'Click',
          'label':    title + ' -- ' + accordion
        });
      } // End of function.

      /**
       * Record click on solution teaser accordion.
       */
      function gtmSolutionTeaserClick(e) {

        var accordion;
        try {
          accordion = e.target
            .closest('.field-collection-item-field-gateway-need-accordion')
            .querySelector('.accordion-section-teaser')
            .querySelector('h2')
            .innerHTML
            .replace(/&[a-z]+;/, '')
            .replace(/[^0-9a-zA-Z -_]+/, ' ');
        }
        catch (e) {
            accordion = 'accordion not found';
        }

        var solution;
        try {
          var tempSolution = e.target
            .closest('.field-collection-item-field-solution-page-teaser')
            .querySelector('h3');

          while (tempSolution.children.length > 0) {
            tempSolution = tempSolution.children[0];
          }
          solution = tempSolution.innerHTML
            .replace(/&[a-z]+;/, '')
            .replace(/[^0-9a-zA-Z -_]+/, ' ');
        }
        catch (e) {
            solution = 'solution not found';
        }

        console.log(title + ' -- ' + accordion + ' -- ' + solution);

        dataLayer.push({
          'event':    'trackEventInteractive',
          'category': 'Gateway Solution',
          'action':   'Click',
          'label':    title + ' -- ' + accordion + ' -- ' + solution
        });
      } // End of function.

    } // End of Attach.
  } // End of Drupal behavior.

})(); // End of IIFE.