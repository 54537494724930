(function ($) {

  /**
   * Make the entire Solution Teaser clickable on Gateway Nodes.
   */
  Drupal.behaviors.linkedSolutionTeaser = {
    attach: function (context, settings) {
      $('.field-collection-item-field-solution-page-teaser').click(function() {
        window.location=$(this).find('.field-name-field-solution-link-text a').attr('href');
        return false;
      });
    }
  };

  /**
   * This behavior controls the Accordion functionality on Gateway nodes.
   *
   */
  Drupal.behaviors.gatewayAccordion = {
    attach: function (context, settings) {
      var collapseTeaser = $('.accordion-section-teaser').removeClass('open');
      $('.accordion-section-teaser').click(function() {
        if($(this).next('.accordion-section-content').is(':visible')) {
          $(this).removeClass('open').next('.accordion-section-content').slideUp();
        } else {
          $('.accordion-section-content').slideUp().prev('.accordion-section-teaser').removeClass('open');
          $(this).addClass('open').next('.accordion-section-content').slideToggle();
        }
      });
      $('.accordion-close').click(function() {
        $(this).closest('.accordion-section-content').slideUp().prev('.accordion-section-teaser').removeClass('open');
      });
    }
  };

  /**
   * After a user scrolls 'x' pixels down the page, the "Sticky Helper" appears.
   */
  Drupal.behaviors.stickyHelperAppear = {
    attach: function (context, settings) {
      $(window).scroll(function() {
        if ($(this).scrollTop() >= 200) {
          // alert("You've scrolled 200 pixels.");
          $('#sticky-helper').stop().animate({opacity: 0.9}, 'fast').removeClass('hidden');
        } else {
          $('#sticky-helper').stop().animate({opacity: 0}, 'fast').addClass('hidden');
        }
      });
    }
  };

  /**
   * When use click on the "Top" link in the "Sticky Header", the user is
   * automatically scrolled back to the top of the page.
   */
  Drupal.behaviors.backToTop = {
    attach: function (context, settings) {
      $('#back-to-top').click(function(){
        $('html, body').animate({scrollTop: 0}, 'slow');
        return false;
      });
    }
  };

  Drupal.behaviors.responsiveTriangle = {
    attach: function (context, settings) {
      $(window).on("resize", function () {
        $('.solution-product-header').each(function(){
          var self = $(this),
              width = (self.width() / 2);
          $('.triangle', self).css({
            'border-left-width' : width,
            'border-right-width' : width
          });
        });
      }).resize();
    }
  };

  Drupal.behaviors.videoPromoModal = {
    attach: function (context, settings) {
      //$('.node-video-promo_display .field-collection-item--field-promotion-information--promo-display a').lightbox();
    }
  };

})(jQuery);
