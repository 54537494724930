;(function ($) {

//set up a global variable to catch the default search text, we'll use it to check against later
  $(document).ready(function () {
    searchDefault = $('#search-bar-text').val();


    // 12/14/17 - brand family redesign
    // run on doc.ready
    brand_family_margin_resize();

    // run resize of the window
    $(window).resize(brand_family_margin_resize);

  });

  // 12/14/17 - brand family redesign
  // set isotope container margin to -25px on desktop & mobile
  // in order to have items flush with the RSB
  function brand_family_margin_resize() {
    if (($(window).width() > 768) || ($(window).width() <= 375)) {
      $('.page-brand-family #isotope-container').css("margin-right", "-25px");
    }
    else {
      $('.page-brand-family #isotope-container').css("margin-right", "0px");
    }
  }

  // CO - 1/8/15 - having verrry strange issue with taxonomy term translations;
  // can't swapout something without redirecting to a foreign language
  $("label[for=edit-product-filter-586]:lang(en)").text('Low Temperature (<32°F or <0°C)');


//function to run isotope sort
  function isotope_sort() {
    var $container = $('#isotope-container');
    var features = '';
    $('.bef-group-items').children().each(function () {
      if ($(this).hasClass('selected')) {
        features = features + '.' + $(this).find('input').val();
      }
    });

    $container.isotope({filter: features});

//to work in tangent with the text search, isotope has to actually hide and show divs and not just play with opacity
    $(".isotope-item").each(function () {
      if ($(this).css('opacity') == 0) {
        $(this).hide();
      }
      else {
        $(this).show();
      }
    });

//if we've hidden everything, display the no results found block
    check_if_empty();

  }

//function to check if all the products are hidden
  function check_if_empty() {

    if ($('#isotope-container').children(':visible').length == 0) {
      //if we're hiding all the products then we need to resize the container div
      //isotope may not have done this if the text search did the final hiding
      $('#isotope-container').css("height", "0px");
      $('#no-results-wrapper').fadeIn("fast");
    }
    else {
      //if there are products we want to display we need to run isotope sort but not call the function
      //we need isotope to recalculate it's container div's height but calling the function would cause infinate recursion
      var $container = $('#isotope-container');
      var features = '';
      $('.bef-group-items').children().each(function () {
        if ($(this).hasClass('selected')) {
          features = features + '.' + $(this).find('input').val();
        }
      });

      $container.isotope({filter: features});
      $('#no-results-wrapper').hide();
    }

  }

//function to handle text and term searching
  function searchBarSort() {
    var needle = '';

    //don't run this if the text area has the default text in it, that is not a term
    if ($('#search-bar-text').val() != searchDefault) {
      needle = $('#search-bar-text').val().trim().toLowerCase();
    }
    if (needle.length > 0) {
      $(".isotope-item").each(function () {
        //grab product titles and terms from the div's data field and search for the text area's text in those
        var title = $(this).find('.product-title');
        var terms = $(this).data('terms');
        if ((title.text().toLowerCase().indexOf(needle) >= 0 || terms.toLowerCase().indexOf(needle) >= 0) && title.parent().css("opacity") > 0) {
          //to work in tangent with isotope we have to reset the position of the product divs, otherwise they will not resort.css
          $(this).show();

        }
        else {
          $(this).css("position", "static");
          $(this).hide();

        }
      });
    }
    else {
      //call isotope sort but i forget the reason, it's important though
      isotope_sort();

    }
    //if text search has hidden everything, show the no results block
    check_if_empty();
  }

//function to reset everything, one at a time...
  function resetFilter() {
    var $container = $('#isotope-container');
    $container.fadeOut(function () {
      $container.fadeIn();
    });

    $(".bef-group-items input:checked").parent().removeClass("selected");
    $(".bef-group-items input").parent().parent().parent().find(".bef-group-heading").removeClass("active");
    $(".bef-group-items input").parent().parent().slideUp();
    $(".bef-group-items input:checked").parent().children("div.deselect").css("visibility", "hidden");
    $(".bef-group-items input:checked").parent().children("div.select").removeClass("checked");
    $(".bef-group-items input:checked").attr("checked", false);
    $("#search-breadcrumbs-terms").children().each(function () {
      $(this).remove();
    });

    $("#search-bar-text").val(searchDefault);

    $('html, body').animate({scrollTop: $('#product-search-container').offset().top}, 750);

//recalling sorts after we've reset filter options should show all products
    isotope_sort();
    searchBarSort();

//don't reload the page with the button
    return false;
  }

//search bar default text
  Drupal.behaviors.searchBarDefault = {
    attach: function (context) {
      $('#search-bar-text').focus(function () {
        if (this.value === this.defaultValue) {
          this.value = '';
        }
      });
      $('#search-bar-text').blur(function () {
        if (this.value === '') {
          this.value = this.defaultValue;
        }
      });
    }
  }

// if bef-checkboxes is empty, that means that
// no suitable filter terms were found. hide the search container & filter block
// see `hidglobal_select_as_checkboxes.inc` for possible reasons why
  Drupal.behaviors.hideFilter = {
    attach: function (context) {
      if ($('.bef-checkboxes').children().length <= 0) {
        $("section.product_filter_block").css("display", "none");
        $("#product-search-container").css("display", "none");
      }
    }
  }


//initial isotope setup
  Drupal.behaviors.isotope = {
    attach: function (context) {
      var $container = $('#isotope-container');
      // initialize isotope
      //put isotope initialization inside imagesLoaded( to prevent overlapping see-- http://isotope.metafizzy.co/docs/help.html#images
      $container.imagesLoaded(function () {
        $container.isotope({
          itemSelector: '.isotope-item',
          layoutMode: 'fitRows',
          animationEngine: 'jquery',
          animationOptions: {
            fadeIn: 'slow',
            duration: 0,
            easing: 'linear',
            queue: false
          },
        });
      });
    }
  };

//handle the reset buttons
  Drupal.behaviors.isotopeReset = {
    attach: function (context) {
      $('#block-hid-custom-product-filter').find('#edit-reset').click(resetFilter);
      $('#refresh-filter-button').click(resetFilter);

    }

  }

//move product filter for responsiveness
  Drupal.behaviors.filterPlacement = {
    attach: function (context) {
      if ($(window).width() < 768) {
        var _pf = $(".product_filter_block").clone();
        $(".product_filter_block").hide();

        _pf.prependTo("#product-search-container"); //move product filter above products

      }
    }
  }

//handle sidebar filter clicks
  Drupal.behaviors.filterHeaderDropdown = {
    attach: function (context) {


      $('.bef-group-heading:not(.hid-processed)', context).addClass('hid-processed').each(function () {

        $(this).click(function () {
            if ($(this).hasClass('active')) {
              $(this).removeClass('active');

            } else {
              $(this).addClass('active');
            }
          },
          function () {
            $(this).removeClass('active');
          });

        $(this).click(function () {
          $(this).next('.bef-group-items').slideToggle();
        });
      });
    }
  };

//initial autocomplete setup
  /*
   Drupal.behaviors.autocompleteProducts = {
   attach: function (context) {

   $(function () {
   var availableTags = [];
   $(".isotope-item").each(function () {
   //titles are going to be searchable
   availableTags.push($.trim($(this).find('.product-title').text()));

   //split the data terms on comma and add each one
   var temp = [];
   temp = $(this).data('terms').split(',');
   $.each(temp, function (index, val) {
   if (val != '' && $.inArray(val, availableTags) == -1)
   {
   availableTags.push($.trim(val));
   }
   });

   });
   //call autocomplete
   $("#search-bar-text").autocomplete({
   source: availableTags
   });
   });
   }
   }
   */

//handlle text search click and enter in the text box
  Drupal.behaviors.searchBarSort = {
    attach: function (context) {

      $('#search-bar-submit').click(function () {
        searchBarSort();
      });

      $("#search-bar-text").keyup(function (event) {
        if (event.keyCode == 13) {
          searchBarSort();
        }
      });

    }
  };

//handle toggle by sidebar features
  Drupal.behaviors.selectItem = {
    attach: function (context) {

      /* handle the user selections */


      $(".form-type-bef-checkbox .option").click(
        function (event) {
          event.preventDefault();
          if ($(this).parent().hasClass("selected")) {

            $(this).parent().removeClass("selected");
            $(this).parent().find(":checkbox").removeAttr("checked");
            $(this).parent().children("div.deselect").css("visibility", "hidden");
            $(this).parent().children("div.select").removeClass("checked");
            $('#search-breadcrumbs-terms span.' + $(this).parent().find('input').val()).remove();
            isotope_sort();
            searchBarSort();
            // $('html, body').animate({scrollTop: $('#isotope-container').offset().top}, 750);


          }
          else {

            $(this).parent().addClass("selected");
            $(this).parent().find(":checkbox").attr("checked", "checked");
            $(this).parent().children("div.deselect").css("visibility", "visible");
            $(this).parent().children("div.select").addClass("checked");
            $('#search-breadcrumbs-terms').append('<span class="breadcrumb-term ' + $(this).parent().find('input').val() + '"><a href="#" class="breadcrumb-remove"><span class="breadcrumb-grey">x</span> ' + $(this).text() + '</a> <span class="breadcrumb-slash">/</span> </span>');
            isotope_sort();
            searchBarSort();

            //$('html, body').animate({scrollTop: $('#isotope-container').offset().top}, 750);
          }
        }
      );

      $('.breadcrumb-term').live("click",
        function (event) {
          $classes = $(this).attr("class").split(' ');
          $item = $('.bef-group-items').find('.form-item-edit-product-features-' + $classes[1]);
          $item.removeClass("selected");
          $item.find(":checkbox").removeAttr("checked");
          $item.find(".deselect").css("visibility", "hidden");
          $item.children("div.select").removeClass("checked");
          $(this).remove();
          isotope_sort();
          searchBarSort();
          return false;
        }
      );


      $(".bef-group-items .deselect").click(
        function (event) {
          event.preventDefault();
          $(this).parent().removeClass("selected");
          $(this).parent().find(":checkbox").removeAttr("checked");
          $(this).css("visibility", "hidden");
          $(this).parent().children("div.select").removeClass("checked");
          $('#search-breadcrumbs-terms span.' + $(this).parent().find('input').val()).remove();
          isotope_sort();
          searchBarSort();
          //$('html, body').animate({scrollTop: $('#isotope-container').offset().top}, 750);
        }
      );

      /*
       $("#views-exposed-form-product-family-fam-page .bef-group-heading").click(function(){
       setTimeout(  function(){
       var contentHeight = $("#content-left").height();
       var sideHeight = $("#sidebar").height();
       var product_filter = $("#block-hid-custom-product-filter").height();
       var sideHeight_children = 0;
       // var sideHeight_children = $('.region-aside').each

       $(".region-aside").children().each(function() {
       sideHeight_children = sideHeight_children + $(this).outerHeight(true);

       });

       if(contentHeight < sideHeight_children) {

       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(sideHeight_children);
       } else {
       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(contentHeight);
       }

       }, 400 );

       });


       $("#views-exposed-form-product-display-page legend a.fieldset-title").click(function(){
       setTimeout(  function(){
       var contentHeight = $("#content-left").height();
       var sideHeight = $("#sidebar").height();
       var product_filter = $("#block-views-exp-product-display-page").height();
       var sideHeight_children = 0;
       // var sideHeight_children = $('.region-aside').each

       $(".region-aside").children().each(function() {
       sideHeight_children = sideHeight_children + $(this).outerHeight();

       });

       if(contentHeight < sideHeight_children) {

       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(sideHeight_children);
       } else {
       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(contentHeight);
       }

       }, 400 );


       });


       $("#views-exposed-form-partner-directory-page legend a.fieldset-title").click(function(){
       setTimeout(  function(){
       var contentHeight = $("#content-left").height();
       var sideHeight = $("#sidebar").height();
       var product_filter = $("#block-views-exp-partner-directory-page").height();
       var sideHeight_children = 0;
       // var sideHeight_children = $('.region-aside').each

       $(".region-aside").children().each(function() {
       sideHeight_children = sideHeight_children + $(this).outerHeight();

       });

       if(contentHeight < sideHeight_children) {

       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(sideHeight_children);
       } else {
       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(contentHeight);
       }

       }, 400 );


       });
       */

      /*
       * Edited the next function for Partner Directory to include the Invest with Confidence block's height
       * in the calculations to accurately restructure the sidebar. This may be required in above functions as well.
       *
       * Please follow comments to duplicate.
       *
       * @author Sunny Kambli
       *
       $("#views-exposed-form-partner-directory-page-1 legend a.fieldset-title").click(function(){
       setTimeout(  function(){
       var sideHeight = $("#sidebar").height();
       var product_filter = $("#block-views-exp-product-display-page").height();
       var hid_genuine_block = $('.hid-genuine-block').height();                    // Added by Sunny
       var contentHeight = $("#content-left").height();

       var sideHeight_children = 0;
       // var sideHeight_children = $('.region-aside').each

       $(".region-aside").children().each(function() {
       sideHeight_children = sideHeight_children + $(this).outerHeight();
       });

       if(contentHeight < sideHeight_children) {
       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(sideHeight_children + hid_genuine_block);           // Edited by Sunny
       } else {
       $('.hid-genuine-block').css({position: 'absolute'});
       $('#sidebar').height(contentHeight);
       }
       }, 400 );
       });
       */


      // if(contentHeight < sideHeight + product_filter){

      //  var diff = contentHeight - sideHeight;

      // var diff = contentHeight - sideHeight+product_filter;
      // if(diff < 75)
      // {
      //   diff = 75;
      //}
      //$('#sidebar').height(sideHeight+product_filter);
      //  $('.hid-genuine-block').css({position: 'absolute'});


    }
  };

//not related to product filter, handle resonsive view of exposed filters
//move product filter for responsiveness
  Drupal.behaviors.exposedfilterPlacement = {
    attach: function (context) {
      if ($(window).width() < 768) {
        var form_ids = {
          // CO - 4/23/15 - removing ww sales from this, was hiding all content on mobile
          // "#views-exposed-form-worldwide-sales-tool-page":1,
          "#views-exposed-form-partners-hid-connect-hid-connect-1": 2,
          "#views-exposed-form-partners-it-dealers": 3,
          "#views-exposed-form-genuine-hid-technology-page": 4,
          "#views-exposed-form-document-library-page": 5,
          "#hid-selective-forms-search-form": 6,
          "#views-exposed-form-customer-service-tool-page": 7
        };

        $.each(form_ids, function (id, i) {
          var $this = $(id).parent().parent().parent();

          var _clone = $this.clone(true);
          _clone.css({"margin-bottom": "20px"});
          $this.hide();
          var attachTo = $("#content-left").find(".view:first > .view-content");


          var empty = $("#content-left").find(".view:first > .view-empty");

          _clone.prependTo(attachTo);
          _clone.prependTo(empty);
        });
      }
    }
  };

//not related to product filter, but shows the logo at the bottom of the sidebar
  Drupal.behaviors.bottomBlock = {
    attach: function (context) {
      /*
       $(window).load(function(){

       var width = $(window).width();
       if (width >= 768){ //do not adjust height if sidebar has dropped below content

       var contentHeight = $("#content-left").height();
       var sideHeight = $("#sidebar").height();

       if(contentHeight > sideHeight){

       var diff = contentHeight - sideHeight;
       if(diff < 75)
       {
       diff = 75;
       }
       $('#sidebar').height(sideHeight+diff);
       $('.hid-genuine-block').css({position: 'absolute'});

       }

       else{
       //$('#sidebar').height(sideHeight+75);
       $('.hid-genuine-block').css({position: 'relative'});
       }

       } else {
       $('.hid-genuine-block').css({position: 'relative'});
       }

       $('.hid-genuine-block').show();


       })
       */

    }
  };

})(jQuery);