(function ($) {
  // Remove Mega Menu dropdown behavior on small and medium.
  if (Modernizr.mq('only screen and (max-width: 50em)')) {
    $('ul.menu > li > a').removeAttr('data-dropdown').removeAttr('data-options');

    var solutions = $(".solution-buttons .tab-title");
    count = solutions.children().length;
    percent = (100 / count);

    solutions.width(percent + '%');
  }

  $(document).ready(function () {
    //Track initial banner display
    if ( $(".orbit-slides-container").length ) {
      var label = $(".orbit-slides-container li:nth-child(1) > img").attr("title");
      if ( document.documentElement.lang == "zh-hans" ) _gaq.push(['_trackEvent', 'Banner', 'Display', label, 1.00, true]);
      else dataLayer.push({'event': 'trackEvent', 'category': 'Banner', 'action': 'Display', 'label': label, 'value': 1.00});
    }
                                                                                           
    // Hide no results text for product pages.
    if ($('.view-product-family').find('#no-results-wrapper').length == 1) {
      document.getElementById('no-results-wrapper').style.display = 'none';
    }
  });

  // Call foundation.
  $(document).foundation({
    orbit: {
      pause_on_hover: false,
      animation: 'slide', // Sets the type of animation used for transitioning between slides, can also be 'fade'
      timer_speed: 8000, // Sets the amount of time in milliseconds before transitioning a slide
      next_on_click: false, // Advance to next slide on click
      animation_speed: 500, // Sets the amount of time in milliseconds the transition between slides will last
      slide_number: false,
      stack_on_small: true, // Sets the slides to stack on the small breakpoint
      timer: true,
      bullets: true // Does the slider have bullets visible?
    },
    topbar: {
      sticky_class: 'sticky',
      custom_back_text: true, // Set this to false and it will pull the top level link name as the back text
      is_hover: true,
      mobile_show_parent_link: false, // will copy parent links into dropdowns for mobile navigation
      scrolltop: true // jump to top when sticky nav menu toggle is clicked
    }
  });

  // Get total number of slides and set bullet widths.
  if (Modernizr.mq('only screen and (min-width: 35.063em)')) {
    var bullets = $(".orbit-bullets");
    count = bullets.children().length;
    margins = count - 1;
    percent = (100 / count) - (margins * 2) / count;

    bullets.children().width(percent + '%');
  }

  // this is the function that makes the background scroll then lock

  $(window).scroll(function (e) {
    if ($(window).scrollTop() > 1050) {
      $('.imagefill').addClass("fix-imagefill");
    } else {
      $('.imagefill').removeClass("fix-imagefill");
    }

  });

  equalheight = function (container) {

    var currentTallest = 0,
      currentRowStart = 0,
      rowDivs = new Array(),
      $el,
      topPosition = 0;
    $(container).each(function () {

      $el = $(this);
      $($el).height('auto');
      topPostion = $el.position().top;

      if (currentRowStart != topPostion) {
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
          rowDivs[currentDiv].height(currentTallest);
        }
        rowDivs.length = 0; // empty the array
        currentRowStart = topPostion;
        currentTallest = $el.height();
        rowDivs.push($el);
      } else {
        rowDivs.push($el);
        currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
      }
      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].height(currentTallest);
      }
    });
  };

  $(window).load(function () {
    equalheight('.region-footer .block-inner');
  });


  $(window).resize(function () {
    equalheight('.region-footer .block-inner');
  });


  // this is the function that controls the hover over on front page teasers - CE

  //$('.video-teaser, .news-teaser, .blog-teaser, .event-teaser, .tool-teaser, .paper-teaser').hover(
  //  function () {
  //    $(this).css('height', '100%');
  //  },
  //  function () {
  //    $(this).css('height', '55px');
  //});
  /**
   * increase hover target to parent div
   * pcp - 2015-03-31
   */
  $('div.teaser').hover(function () {
    $(this).children('div[class*=-teaser]').css('height', '100%');
  }, function () {
    $(this).children('div[class*=-teaser]').css('height', '55px');
  });


  /*
   * For toggle on Contact Us Menu block
   *
   * @author Caryn / CO
   * 2016-02-19 - PCP - Add close button behavior
   */

  $('.block-sf-contact-us-button').click(function () {
    var contact_block = $('.block-sf-contact-us-block');
    var footerOverlay = document.querySelector('.footer-overlay')
    footerOverlay.classList.toggle('footer-overlay__open');
    if ($(contact_block).is(':hidden')) {
      $('.contact-button__close').css({
          'font-size' : '130%',
          'right' : '15px',
          'top' : '-26px',
          'height' : '30px',
          'width' : '30px'
        });
    }
    else {
      $('.contact-button__close').css({
          'font-size' : '0',
          'right' : '60px',
          'top' : '0',
          'height' : '0',
          'width' : '0'
        });
    }
    $(contact_block).slideToggle("slow");
  });

  /**
   * prevent default behavior of A tag on contact us block
   * pcp - 2015-05-07
   */
  $('.block-sf-contact-us-button .contact-button a[href="#"]').click(
    function(e){
      e.preventDefault();
    });


  /* detect touch */
  if ("ontouchstart" in window) {
    document.documentElement.className = document.documentElement.className + " touch";
  }
  if (!$("html").hasClass("touch")) {
    /* background fix */
    $(".parallax").css("background-attachment", "fixed");
  }

  /* fix vertical when not overflow
   call fullscreenFix() if .fullscreen content changes */
  function fullscreenFix() {
    var h = $('body').height();
    // set .fullscreen height
    $(".content-b").each(function (i) {
      if ($(this).innerHeight() <= h) {
        $(this).closest(".fullscreen").addClass("not-overflow");
      }
    });
  }

  $(window).resize(fullscreenFix);
  fullscreenFix();


  /* resize background images */
  function backgroundResize() {
    var windowH = $(window).height();
    $(".background").each(function (i) {
      var path = $(this);
      // variables
      var contW = path.width();
      var contH = path.height();
      var imgW = path.attr("data-img-width");
      var imgH = path.attr("data-img-height");
      var ratio = imgW / imgH;
      // overflowing difference
      var diff = parseFloat(path.attr("data-diff"));
      diff = diff ? diff : 0;
      // remaining height to have fullscreen image only on parallax
      var remainingH = 0;
      if (path.hasClass("parallax") && !$("html").hasClass("touch")) {
        var maxH = contH > windowH ? contH : windowH;
        remainingH = windowH - contH;
      }
      // set img values depending on cont
      imgH = contH + remainingH + diff;
      imgW = imgH * ratio;
      // fix when too large
      if (contW > imgW) {
        imgW = contW;
        imgH = imgW / ratio;
      }
      //
      path.data("resized-imgW", imgW);
      path.data("resized-imgH", imgH);
      path.css("background-size", imgW + "px " + imgH + "px");
    });
  }

  $(window).resize(backgroundResize);
  $(window).focus(backgroundResize);
  backgroundResize();

  /* Dynamic Parallax Positioning */
  function fixParallaxPosition() {

    function getParallaxOffset(el) {
      var currEl = el;
      var realTop = 0;
      var html = document.querySelector('html');
      while (currEl !== html && currEl !== null) {
        realTop += currEl.offsetTop;
        currEl = currEl.offsetParent;
      }
      var mega = document.querySelector('.mega-menu > .top-bar');
      realTop -= mega.clientHeight;
      return realTop;
    }

    if (typeof window.parallaxImageHeights !== 'object') {
        window.parallaxImageHeights = {};
      }
    var imgHeights = window.parallaxImageHeights;
    var bgParallax = document.querySelectorAll('.parallax');

    for (var i = 0; i < bgParallax.length; i++) {

      var bg = bgParallax[i];
      var parallaxImage = bg.style.backgroundImage.replace('url(', '').replace(')', '').replace(/"/g, '');
      var parallaxKey = parallaxImage.split('/').pop();

      if ((typeof imgHeights[parallaxKey] === 'undefined') ||
        (imgHeights[parallaxKey] === 0)) {
        var tmpImg = new Image();
        tmpImg.src = parallaxImage;
        imgHeights[parallaxKey] = tmpImg.height;
        setTimeout(fixParallaxPosition, 1000);
      }

      var parallaxOffset = getParallaxOffset(bg);
      var parallaxMax = window.innerHeight - window.parallaxImageHeights[parallaxKey];
      var parallaxY = parallaxOffset + parallaxMax * (bg.getBoundingClientRect().y / window.innerHeight);

      bg.style.backgroundPositionY = parseInt(parallaxY) + 'px';
    }
  }

  $(function () {
    if (!$('html').hasClass('touch')) {
      fixParallaxPosition();
      $(window).resize(fixParallaxPosition);
      $(window).scroll(fixParallaxPosition);
    }
    $(window).on("load", fixParallaxPosition);
  });

  /**
   * hamburger fix from
   * https://github.com/zurb/foundation/issues/5343
   */
  $(function () {
    $('a.right-off-canvas-toggle').on('click', function () {
      //Don't need to put anything here
    });
  });

  /**
   * 2016-11-01 - PCP
   * Fix center alignment of HP promos when there is only 1 line of text.
   */
  $(function(){
    var h6 = document.querySelectorAll('.teaser h6');
    for (var i = 0; i < h6.length; i++) {
      var teaser = h6[i];
      var teaser_styles = window.getComputedStyle(teaser);
      if (teaser_styles.height <= teaser_styles.minHeight) {
        teaser.style.lineHeight = teaser_styles.minHeight;
      }
    }
  });


  /**
   * front page reveal behavior
   * pcp - 2015-04-01
   */
  $(function () {
    $('html.csstransitions body.front').addClass('visible');
    $('html.csstransitions body.front nav.top-bar').css('opacity', '1').css('top', '0');
    $('html.csstransitions body.front section.banner-slideshow').css('opacity', '1');
    $('html.csstransitions body.front section.main-section').css('opacity', '1');
    $('html.csstransitions body.front div.sticky-footer').css('opacity', '1').css('bottom', '0');
  });

  /**
   * request info on aside
   * @TODO: rework markup/code to make this hack not needed
   * 2015-06-19 update: add support for lumidigm block (#92)
   */
  $(function(){
    $('#block-block-104').parent().css('background', 'none').css('border', 'none');
    $('#block-block-92').parent().css('background', 'none').css('border', 'none')
      .css('overflow', 'auto');
  });


  /**
   * remove request info from embedded-solutions/digital-ceritificates page
   */
  $(function(){
    var currentPath = location.pathname;
    if (currentPath === '/solutions/digital-certificates') {
      $('.request-info-block').css('display', 'none');
    }
  });

  /**
   * style iframed call to drupal pages
   * @TODO: Remove all internal iframed content.
   * No need to iframe content from your own site!
   */
  $(function() {
    $('iframe[src^="/"]').filter(function() {
      return this.src.indexOf(location.origin) === 0;
    }).on('load', function(){
      $(this).contents()
        .find('.sticky-footer, .mega-menu, .tab-bar, [itemprop=breadcrumb], #admin-menu, #environment-indicator, .tabs, footer')
        .css('display', 'none');
      $(this).contents().find('.off-canvas-wrap')
        .css('cssText', 'top: 0; margin: 0 !important');
      $(this).contents().find('body')
        .css('cssText', 'margin: 0 !important');
    });
  });


  $.fn.OneClickSelect = function () {
  return $(this).on('click', function () {

    var range, selection;

    if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(this);
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(this);
      range.select();
    }
  });
};

  $(function() {
    // Apply to these elements
    $('#all-select').OneClickSelect();
  });

  // Track banner displays
  $(".orbit-slides-container").on("after-slide-change.fndtn.orbit", function(event, orbit) {
    var index = orbit.slide_number + 1;
    var label = $(".orbit-slides-container li:nth-child(" + index + ") > img").attr("title");
    if ( document.documentElement.lang == "zh-hans" ) _gaq.push(['_trackEvent', 'Banner', 'Display', label, 1.00, true]);
    else dataLayer.push({'event': 'trackEvent', 'category': 'Banner', 'action': 'Display', 'label': label, 'value': 1.00});
  });

  /**
   * switch visibility of footer and reduce height of top-bar on scroll
   * rsm - 10/14/2015
   */
  $(function() {
    //Disable functionality on versions of IE.
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    var trident = ua.indexOf('Trident/');

    if ((msie > -1) || (trident > -1)) {
      console.log('Microsoft Internet Explorer Detected');
      return;
    }

    var lastScrollTop = $(window).scrollTop();
    $(window).scroll(function() {
      var scrollTop = $(window).scrollTop();
      if (scrollTop < lastScrollTop) {
        $('body').removeClass('reduced');
        $('#web-logo').height('40px');
      } else {
        $('body').addClass('reduced');
        $('#web-logo').height('32px');
      }
      lastScrollTop = scrollTop;
    });
  });

  /**
   * allow user to toggle search input box
   * rsm - 10/15/2015
   */
   function toggleSearchBox() {
     var $this = $('.block-views-exp-search-page input');
     $this.wrap('<div class="toggle-holder" />');
     var helper = $('<span class="toggle-helper"></span>');
     $this.parent().append(helper);
     $('.toggle-helper').click(function (e) {
       e.stopPropagation();
       $('.block-views-exp-search-page').toggleClass('search-up');
     });
   }

  /**
   * show the reduced header search input box on click
   * rsm - 10/15/2015
   */
  $(function () {
    var searchBoxToggle = false;
    $('nav').delegate('body.reduced .top-bar .block-views-exp-search-page', 'click', function() {
      if (!$(this).hasClass('search-up')) {
        $(this).toggleClass('search-up');
        if (!searchBoxToggle) {
          toggleSearchBox();
          searchBoxToggle = true;
        }
      }
    });
  });

  // Remove class on body to remove aside on parent but not affect the childern with styling - YES, Dragons live here!

 $(function() {
  var megaSelector = 'body.page-product-display-cards-and-credentials-e-passports';
      megaSelector += ', body.page-product-display-cards-and-credentials-e-id-cards';
      megaSelector += ', body.page-product-display-cards-and-credentials-vango';
      megaSelector += ', body.page-product-display-cards-and-credentials-legic';
      megaSelector += ', body.page-product-display-cards-and-credentials-hitag';
      megaSelector += ', body.page-product-display-cards-and-credentials-activid';
      megaSelector += ', body.page-product-display-cards-and-credentials-hid-proximity';
      megaSelector += ', body.page-product-display-cards-and-credentials-flexsmart-mifare-desfire';
      megaSelector += ', body.page-product-display-cards-and-credentials-iclass-seos';
      megaSelector += ', body.page-product-display-cards-and-credentials-indala';
      megaSelector += ', body.page-product-display-cards-and-credentials-crescendo';
      megaSelector += ', body.page-product-display-cards-and-credentials-iclass-se';
      megaSelector += ', body.page-product-display-cards-and-credentials-iclass';
      megaSelector += ', body.page-product-display-cards-and-credentials-ultracard';

  $(megaSelector).removeClass('page-product-display-cards-and-credentials');
 });

  // Detect IE11 and IE10 based on user agent

  var UA = navigator.userAgent;
  var html = document.documentElement;
  if (UA.indexOf('IEMobile') === -1) {
      if ((UA.indexOf('rv:11.') !== -1) && (!html.classList.contains('ie11')) && window.navigator.msPointerEnabled) {
          html.classList.add('ie11');
      } else if ((UA.indexOf('MSIE 10.') !== -1) && (!html.classList.contains('ie10')) && window.navigator.msPointerEnabled) {
          html.classList.add('ie10');
      }
  }


  $(function() {

    // Iterate the video teaser modal ID to work with multiple videos on front page

    $("a[id^='video-mod']").each(function(i) {
      $(this).attr('id', 'video-modal' + (i + 1));
      $(this).attr('data-reveal-id', 'videoModal' + (i + 1));
    });

    $("div[id^='videoMod']").each(function(i) {
      $(this).attr('id', 'videoModal' + (i + 1));
    });

    $('.video-source').attr('src', function(i, src) {
      return src.replace( 'watch?v=', 'embed/' ).replace(/&t=[0-9]*s/, '');
    });

    $('iframe').attr('src', function(i, src) {
      return (typeof src == 'undefined') ? null : src.replace('http:','');
    });

  });

  $(function() {
    $('.front .messages.error').parent().removeClass('columns');
  });

  /**
   * Leave only 1 image on the RSB dynlinks blocks
   */
  Drupal.behaviors.dynlinks_image = {
    attach: function (context, settings) {
      $('.dynamic-link img').each(function(i, v) {
        if (i > 0) {
          $(this).css('display', 'none');
        }
      });
    }
  };

  /**
   * Hide Lingotek "profile ID not found" message for non-admins
   */
  Drupal.behaviors.lingotek_profile_message = {
    attach: function (context, settings) {
      $('body:not(.admin-menu) .messages.error:contains(Lingotek profile ID  not found)').css('display', 'none');
    }
  };

  /**
   * PCP - 2017-01-10.
   * Make navBar scroll under admin menu, if present.
   */
  Drupal.behaviors.adjust_navbar = {
    attach: function (context, settings) {
      // Define 2 scoped variables to prevent constant re-parsing of DOM elements.
      var navBar         = document.querySelector('nav.top-bar');
      var navTabBar      = document.querySelector('nav.tab-bar');
      var adminMenu      = document.querySelector('#admin-menu');
      var aside          = document.querySelector('aside.right-off-canvas-menu');
      var navBarDelay    = navBar ? window.getComputedStyle(navBar).transitionDelay : null;
      var navBarDuration = navBar ? window.getComputedStyle(navBar).transitionDuration : null;
      // Define new function for "onScroll" event.
      function adjustNavBar() {
        // Only change position if everything is set.
        if (navBar != null && adminMenu != null) {
          var newBottom       = Math.max(adminMenu.getBoundingClientRect().bottom, 0);
          navBar.style.top    = newBottom + 'px';
          navTabBar.style.top = newBottom - 29 + 'px';
          aside.style.top     = newBottom - 29 + 'px';
          // Cancel some animation styles if the admin-menu is showing.
          if (adminMenu.getBoundingClientRect().bottom > 0) {
            navBar.style.transitionDelay    = '0s';
            navBar.style.transitionDuration = '0s';
          }
          // Restore animation styles when the admin-menu goes away.
          else {
            navBar.style.transitionDelay    = navBarDelay;
            navBar.style.transitionDuration = navBarDuration;
          }
          // End of if admin-menu is showing.
        }
        // End of if variables are defined.
      }

      // End of function adjustNavBar().
      window.addEventListener('scroll', adjustNavBar);
      window.addEventListener('resize', adjustNavBar);
      setTimeout(adjustNavBar, 1000);
    }
  };
  // End of navBar scroll behavior.

  /**
   * PCP - 2017-01-11
   * Scroll anchor beyond menu bar.
   */
  Drupal.behaviors.adjust_anchor = {
    attach: function (context, settings) {
      // Only do something if page has a hash anchor.
      var anchor = window.location.hash.substring(1);
      if (anchor && anchor.indexOf('!') === -1) {
        $(function () {
          // Add "onload" handler.
          window.onload = function () {
            setTimeout(adjustHashAnchor, 50);
          };
          // End of onload handler.
        });
      }
      // End of has hash anchor.

      /**
       * Adjusts pushes page down if needed.
       */
      function adjustHashAnchor() {
        if (window.location.hash) {
          var hash      = document.querySelector(window.location.hash);
          var body      = document.querySelector('body');
          var nav       = document.querySelector('nav.top-bar');
          var navBottom = nav.getBoundingClientRect().bottom;
          var hashTop   = hash.getBoundingClientRect().top;
          // If needs adjustment, push page down a bit.
          if (navBottom + 20 > hashTop) {
            body.scrollTop -= navBottom + 20 - hashTop;
            setTimeout(adjustHashAnchor, 50);
          }
          // End of if page needs adjustment.
          }
        // End of if the window has hash
      }
      // End of function adjustHashAnchor.
    }
  };
  // End of anchor scroll behavior.

})(jQuery);
