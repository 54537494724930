/**
 * Created by peter on 2/26/16.
 */
;(function($) {
  $(function() {
    var items = '<ul>';
    $('.field-name-field-solution-support > .field-items > .field-item:gt(0)').each(function() {
      var currTitle = $(this).find('.solution-support-title').text();
      var currId = currTitle.replace(/[^a-z|0-9]/gi,'_').toLowerCase();
      items += '<li><a href="#' + currId + '">' + currTitle + '</a></li>';
      $(this).attr('id', currId);
    });
    items += '</ul>';
    var toc = $('#table_of_contents');
    toc.html(items);
    var browser = navigator.userAgent;
    if (browser.indexOf("MSIE") === -1) {
      toc.find('a').each(function() {
        $(this).on('click', function(e) {
          var href=$(this).attr('href');
          $('body, html').animate({scrollTop: $(href).offset().top - 65}, {duration: 1500, easing: 'easeOutCubic'});
          e.preventDefault();
        });
      });
    }
  });
})(jQuery);