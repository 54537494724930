(function ($) {
  Drupal.behaviors.workbench = {
    attach: function (context, settings) {
      // Implements the download behavior on /workbench.
      $('#workbench-download').click(function () {
        window.open("https://www.hidglobal.com/sites/default/files/drivers/hid_fargoworkbench_v3.2.1.13_setup.exe");
      });
      $('input#eula').click(function () {
        if (this.checked) {
          $('#workbench-download').css('visibility', 'visible');
          $('#workbench-download-fake').css('display', 'none');
        }
        else {
          $('#workbench-download').css('visibility', 'hidden');
          $('#workbench-download-fake').css('display', 'block');
        }
      });
    }
  };
})(jQuery);