/**
 * Created by peter on 9/4/15.
 */

(function ($) {
  Drupal.behaviors.menu_translation = {
    attach: function (context, settings) {
      var hid = Drupal.settings.hidglobal;
      if ((typeof hid.main_navigation[hid.language] !== 'undefined')
          && (typeof hid.main_navigation !== 'undefined')
          && (typeof hid.main_navigation[hid.language] !== 'undefined')) {
        var menu = hid.main_navigation[hid.language];
        for (item in menu) {
          $('#block-menu-menu-main-navigation-menu a')
            .filter(function() {
              return $(this).html() == item;
            })
            .text(menu[item]);
        } // for each menu item to replace
      } // if we have a translation for this language
    }
  }
})(jQuery);
