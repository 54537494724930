(function ($) {
  $(".product-tab").click(function(){
    var tab = $(this).attr("rel");
    $(".product-tab-content").hide();
    $("#" + tab).show();
  });

  $("#edit-product-features-wrapper .bef-group-heading").click(function(){
    $(this).parent().toggleClass('collapsible');
  });

  /**
   * PCP - 2015-01-05
   * Add #tech / #specs behavior to product pages
   */
  if (window.location.pathname.split('/')[1] == 'products') {
    switch (window.location.hash) {
      case '#specs':
        $('span.product-tab[rel="specs"]').click();
        break;
      case '#tech':
        $('span.product-tab[rel="expansions"]').click();
        break;
    }
  }
}(jQuery));
