;(function ($) {
  Drupal.behaviors.breadcrumbs_solution = {
    attach: function (context, settings) {
      var count = 0;
      var crumbs;
      setTimeout(checkBreadCrumbs, 500);

      function checkBreadCrumbs() {
        crumbs = document.querySelector("[itemprop=breadcrumb]");
        if (crumbs === null && count < 10) {
          count += 1;
          setTimeout(checkBreadCrumbs, 500);
        }
        else if(count < 10) {
          fixBreadcrumbs();
        }
      }

      function fixBreadcrumbs() {
        var crumbsListItems = crumbs.querySelectorAll("li");
        var crumbsList = crumbs.querySelector("ol");
        var paths = window.location.pathname.split("/");
        if (window.location.pathname.indexOf("solutions") > 0 && crumbsListItems.length === 2 && paths.length === 4) {
          var crumb = document.createElement("li");
          var newTitle = paths[2]
            .split("-")
            .map(function(e) {
              return e.charAt(0)
                .toUpperCase() + e.substring(1);
            }).join(" ");
          crumb.innerHTML = "<a href='/" + paths[2] + "'>" + newTitle + "</a> > ";
          crumbsList.insertBefore(crumb, crumbsListItems[crumbsListItems.length - 1]);
        }

        // 2018-12-19 - PCP - Fix for BU-induced breadcrumbs
        var crumbsBU = document.querySelectorAll(".breadcrumb li");
        var badCrumbsBU = ["CID", "EAT", "IAMS", "IDT", "PACS", "SI"];
        for (var curr = 0; curr < crumbsBU.length; curr++) {
          var crumbBU = crumbsBU[curr];
          if (badCrumbsBU.indexOf(crumbBU.innerText.replace(/[^a-zA-Z0-9]/g,"")) >= 0) {
            crumbBU.parentNode.removeChild(crumbBU);
          }
        }
        // end of fix for BU-induced breadcrumbs
      }
    }
  }
})(jQuery);
